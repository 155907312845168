import React, { useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Skeleton from '@mui/material/Skeleton';

const SavingsGraph = props => {
    const [kpi, setKpi] = useState('ghg');

    const handleKpiChange = (e, v) => {
        if(v === kpi) return;
        setKpi(v);
    }

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (kpi === 'cost' ? '$' : '') + (tick / 1e6).toFixed(1) + 'M'; 
        else if (Math.abs(tick) >= 1e5) return (kpi === 'cost' ? '$' : '') + (tick / 1e3).toFixed(0) + 'k';
        else return (kpi === 'cost' ? '$' : '') + tick.toLocaleString();
    }

    const formatReadings = reading => {
        if(kpi === 'ghg') return (Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${(Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }

    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        // const ampm = hours >= 12 ? 'PM' : 'AM';
        // const formattedHours = hours % 12 || 12;
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
        const year = date.toLocaleString('default', { year: '2-digit' });
    
        return `${month}/${day}/${year}`;
        // return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm} ${month}/${day}`;
    };

    return (
        <div style={{height:'100%', width:'100%', backgroundColor:'white', padding:`${props.isLoading ? '0' : '2vh 2% 0 2%'}`, boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
            {!props.isLoading ? <><div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                <div style={{flex:'1'}}></div>
                <h4 style={{flex:'1', textAlign:'center'}}>{kpi === 'ghg' ? 'Emissions' : 'Financial'} Savings</h4>
                <div style={{flex:'1', textAlign:'center'}}>
                    <ToggleButtonGroup
                        value={kpi}
                        exclusive
                        onChange={handleKpiChange}
                        aria-label="text alignment"
                        size='small'
                    >
                        <ToggleButton value="ghg" aria-label="left aligned">
                            <p style={{margin:'0'}}>GHG</p>
                        </ToggleButton>
                        <ToggleButton value="cost" aria-label="centered">
                            <p style={{margin:'0'}}>Cost</p>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <ResponsiveContainer height='87%' width='100%'>
                <ComposedChart data={props.data}>
                    <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                        tickFormatter={formatXAxisTick} interval={Math.ceil(props.data.length / 7)} textAnchor='start'/>

                    <YAxis yAxisId="left" tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                    label={{value: `${kpi === 'ghg' ? 'GHG savings (kg CO2e)' : 'Cost savings ($)'}`, position: 'outside', offset: 0, angle: -90, dx: -30, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>

                    <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                    label={{value: `Total savings ${kpi === 'ghg' ? ' (kg CO2e)' : ' ($)'}`,position: 'outside', offset: 0, angle: 90, dx: 30, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>
                    
                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }}
                        labelFormatter={formatXAxisTick}
                        wrapperStyle={{ outline: "none" }}
                        formatter={formatReadings}
                    />

                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar yAxisId="left" dataKey={`${kpi === 'ghg' ? 'savings.totalGHG' : 'savings.totalCost'}`} fill='rgb(8,114,182)' />
                    <Line yAxisId="right" type="monotone" dataKey={`${kpi === 'ghg' ? 'savings.cumulativeGHG' : 'savings.cumulativeCost'}`} stroke='rgb(0,177,168)' dot={false}/>
                </ComposedChart>
            </ResponsiveContainer></>
            :
            <Skeleton style={{height:'100%', width:'100%'}}/>}
        </div>
    );
}

export default SavingsGraph;