import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const EmissionTrendGraph = props => {
    const CustomTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                props.isFinancial ?
                <div style={{backgroundColor:'#f5f5f5', border:'1px solid lightgrey',fontSize: '0.75rem', padding:'0.4rem'}}>
                    <p style={{margin:'0'}}><strong>{payload[0].payload.year}</strong></p>

                    <p style={{margin:'0'}}><strong><span style={{color:'#FFC000'}}>Actual</span>/<span style={{color:'rgb(8,114,182)'}}>projected:</span>
                    </strong> {payload[0].payload.value > 1 ? payload[0].payload.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : payload[0].payload.value}</p>
                </div> 
                :
                <div style={{backgroundColor:'#f5f5f5', border:'1px solid lightgrey',fontSize: '0.75rem', padding:'0.4rem'}}>
                    <p style={{margin:'0'}}><strong>{payload[0].payload.year}</strong></p>

                    <p style={{margin:'0'}}><strong><span style={{color:'#FFC000'}}>Actual</span>/<span style={{color:'rgb(8,114,182)'}}>projected:</span>
                    </strong> {payload[0].payload.value > 1 ? payload[0].payload.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : payload[0].payload.value}</p>

                    <p style={{margin:'0'}}><strong style={{color:'rgb(0,175,180)'}}>If 100% impl:
                    </strong> {payload[0].payload.fullImp > 1 ? payload[0].payload.fullImp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : payload[0].payload.fullImp}</p>

                    {/* <p style={{margin:'0'}}><strong style={{color:'rgb(78,79,83)'}}>Prio impl:
                    </strong> {payload[0].payload.baseline > 1 ? payload[0].payload.baseline.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : payload[0].payload.baseline}</p> */}

                    <p style={{margin:'0'}}><strong style={{color:'rgb(213,43,0)'}}>2030 goal:
                    </strong> {payload[0].payload.endGoal > 1 ? payload[0].payload.endGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : payload[0].payload.endGoal}</p>
                </div>
            );
          }
        
          return null;
    }

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (tick / 1e6).toFixed(1) + 'M'; 
        else if (Math.abs(tick) >= 1e4) return (tick / 1e3).toFixed(0) + 'k';
        else return tick.toLocaleString();
    }

    return(
        <>
            <div style={{height:'10%', display:'flex', flexDirection:'row', marginBottom:'1%'}}>
                <h4 style={{margin:'0', flex:'3', fontSize: '1rem'}}>{props.title}</h4>
                {props.subTitle !== '' && <small style={{flex:'1', textAlign:'right', lineHeight:'1rem', fontSize:'0.7rem'}}>{props.subTitle}</small>}
            </div>

            <div style={{height:'92%', display:'flex', flexDirection:'row', width:'100%'}}>
                <div style={{flex:'10'}}>
                    <ResponsiveContainer>
                        <LineChart data={props.data} margin={{ top: 0, right: 25, left: -8, bottom: 5 }}>

                            <defs>
                                <linearGradient id="ghgColorUv" x1="0%" y1="0" x2="100%" y2="0">
                                    <stop offset="0%" stopColor="#FFC000" />
                                    <stop offset={`${props.pct}%`} stopColor="#FFC000" />
                                    <stop offset={`${props.pct}%`} stopColor="rgb(8,114,182)" />
                                    <stop offset={`100%`} stopColor="rgb(8,114,182)" />
                                </linearGradient>
                            </defs>

                            <CartesianGrid strokeDasharray="2 2" vertical={false}/>

                            <XAxis dataKey='year' tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} tickLine={false}/>

                            <YAxis 
                                tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} 
                                label={{value: `${props.yAxisLabel}`, position: 'outside', offset: 0, angle: -90, dx: -16, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}
                                tickFormatter={formatYAxisTick}
                            />

                            {props.isFinancial ? 
                            <>
                                <Line dataKey='value' type='monotone' dot={false} stroke="url(#ghgColorUv)" strokeWidth={2} />
                            </> :
                            <>
                                <Line dataKey='value' type='monotone' dot={false} stroke="url(#ghgColorUv)" strokeWidth={2} />
                                {/* <Line dataKey='baseline' dot={false} strokeDasharray="4 3" strokeWidth={2} stroke='rgb(78,79,83)'/> */}
                                <Line dataKey='endGoal' dot={false} strokeWidth={2} stroke='rgb(213,43,0)'/>
                                <Line dataKey='fullImp' dot={false} strokeDasharray="10 4" strokeWidth={2} stroke='rgb(0,175,180)'/>
                            </>}

                            <Tooltip 
                                content={<CustomTooltip />}
                                wrapperStyle={{ outline: "none" }}
                            />

                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div style={{flex:'2.5', height:'100%'}}>
                    <br/>
                    <br/>

                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft:'10%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <div style={{ width: '10px', height: '10px', backgroundColor: '#FFC000', borderRadius: '50%', marginRight: '10px' }} />
                            <span style={{ fontSize: '12px' }}>Actual</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <div style={{ width: '10px', height: '10px', backgroundColor: 'rgb(8,114,182)', borderRadius: '50%', marginRight: '10px' }} />
                            <span style={{ fontSize: '12px' }}>Projected</span>
                        </div>

                        {!props.isFinancial && 
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <MoreHorizIcon style={{ width: '20px', height: '20px', color: 'rgb(0,175,180)', borderRadius: '50%', marginRight: '5px', marginLeft:'-4px' }}/>
                                <span style={{ fontSize: '12px' }}>If 100% impl.</span>
                            </div>

                            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <MoreHorizIcon style={{ width: '20px', height: '20px', color: 'rgb(78,79,83)', borderRadius: '50%', marginRight: '5px', marginLeft:'-4px' }}/>

                                <span style={{ fontSize: '12px' }}>Prior impl.</span>
                            </div> */}

                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                <div style={{ width: '10px', height: '10px', backgroundColor: 'rgb(213,43,0)', borderRadius: '50%', marginRight: '10px' }} />
                                <span style={{ fontSize: '12px' }}>2030 goal</span>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmissionTrendGraph;